import TagManager from 'react-gtm-module'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Link } from "react-router-dom";
import './index.css';
import Navbar from './Navbar';
import Home from './Home';
import NotFound from './NotFound';
import About from './About';
import FAQ from './FAQ';

import Toolbox from './Toolbox';
//import StyloXR from './StyloXR';
import Timeline from './Timeline';
import WordBubbles from './WordBubbles';
import Connections from './Connections'
import NetworkDiagram from './NetworkDiagram';

import SpeakersAll from './SpeakersAll';
import SpeakerDetails from './SpeakerDetails';
import SpeakerDetailsInner from './SpeakerDetailsInner';

import PeopleAll from './PeopleAll';
import PeopleDetails from './PeopleDetails';
import PeopleDetailsInner from './PeopleDetailsInner';

import ConceptsAll from './ConceptsAll';
import ConceptDetails from './ConceptDetails';
import ConceptDetailsInner from './ConceptDetailsInner';

import InfluencesAll from './InfluencesAll';
import InfluenceDetails from './InfluenceDetails';
import InfluenceDetailsInner from './InfluenceDetailsInner';

import BOMSemanticMap from './BOMSemanticMap';
import SocialNetwork from './SocialNetwork';
import SimilarVerse from './SimilarVerse';
import BibleSemanticMap from './BibleSemanticMap';

import CameosAll from './CameosAll';
import Feedback from './Feedback';


function App() {


  //   a speaker has
  // name
  // years
  // words
  // description
  // fact
  // img
  // circle img

  // function to display expanded menu on nav hover
  const handleNavHoverMenuExpansion = () => {
    console.log('hovering on nav element!');
  }

  const tagManagerArgs = {
      gtmId: 'G-WJ2GP9TQHP'
  }

  TagManager.initialize(tagManagerArgs)

  return (
    <Router>
      <Navbar className='nav-bar'></Navbar>

      <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/about" element={<About/>}></Route>
        <Route path="/feedback" element={<Feedback/>}></Route>
        <Route path="/cameos"  element={<CameosAll/>}></Route>

        <Route path="/cameos/speakers" element={<SpeakersAll/>}></Route>
        <Route path="/cameos/speakers/:speaker_tag" element={<SpeakerDetails/>}></Route>
        <Route path="/cameos/speakers/:category/:speaker_tag" element={<SpeakerDetailsInner/>}></Route>

        <Route path="/cameos/people" element={<PeopleAll/>}></Route>
        <Route path="/cameos/people/:speaker_tag" element={<PeopleDetails/>}></Route>
        <Route path="/cameos/people/:category/:speaker_tag" element={<PeopleDetailsInner/>}></Route>


        <Route path="/cameos/concepts" element={<ConceptsAll/>}></Route>
        <Route path="/cameos/concepts/:speaker_tag" element={<ConceptDetails/>}></Route>
        <Route path="/cameos/concepts/:category/:speaker_tag" element={<ConceptDetailsInner/>}></Route>

        <Route path="/cameos/influences" element={<InfluencesAll/>}></Route>
        <Route path="/cameos/influences/:speaker_tag" element={<InfluenceDetails/>}></Route>
        <Route path="/cameos/influences/:category/:speaker_tag" element={<InfluenceDetailsInner/>}></Route>

        <Route path="/toolbox" element={<Toolbox/>}></Route>
        <Route path="/toolbox/timeline" element={<Timeline/>}></Route>
        <Route path="/toolbox/word-bubbles" element={<WordBubbles/>}></Route>
        <Route path="/toolbox/connections" element={<Connections/>}></Route>
        <Route path="/toolbox/network-diagram" element={<NetworkDiagram/>}></Route>

        <Route path="/toolbox/BOM-semantic-map" element={<BOMSemanticMap/>}></Route>
        <Route path="/toolbox/Bible-semantic-map" element={<BibleSemanticMap/>}></Route>
        <Route path="/toolbox/similar-verse" element={<SimilarVerse/>}></Route>
        <Route path="/toolbox/social-network" element={<SocialNetwork/>}></Route>


        <Route path="/" element={<Home></Home>}></Route>
        <Route path="*" element={<NotFound/>}></Route>
      </Routes>
    </Router>
    
  );
}

export default App;

/*
        <Route path="/faq" element={<FAQ/>}></Route>
*/
//        <Route path="/toolbox/stylo-xr" element={<StyloXR/>}></Route>
